define('semantic-ui-ember/components/ui-dropdown-array', ['exports', 'ember', 'semantic-ui-ember/components/ui-dropdown'], function (exports, _ember, _semanticUiEmberComponentsUiDropdown) {
  'use strict';

  exports['default'] = _semanticUiEmberComponentsUiDropdown['default'].extend({
    content: null,
    find_by: 'id',

    getSelected: function getSelected(selected) {
      if (_ember['default'].isBlank(selected)) {
        return null;
      }
      return _ember['default'].get(selected, this.get('find_by'));
    },

    _onChange: function _onChange(value, text, $element) {
      var _this = this;

      if (!$element) {
        return;
      }

      var record = this.get('content').find(function (item) {
        var current = _ember['default'].get(item, _this.get('find_by'));
        return current === value || (current || '').toString() === value || current === (value || '').toString();
      });
      this.set('selected', record);
    }
  });
});