define('semantic-ui-ember/components/ui-dropdown', ['exports', 'ember', 'semantic-ui-ember/mixins/base', 'semantic-ui-ember/mixins/data-attributes'], function (exports, _ember, _semanticUiEmberMixinsBase, _semanticUiEmberMixinsDataAttributes) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend(_semanticUiEmberMixinsBase['default'], _semanticUiEmberMixinsDataAttributes['default'], {
    module: 'dropdown',
    classNames: ['ui', 'dropdown'],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var selected = this.get('selected');
      if (_ember['default'].isPresent(selected)) {
        this.execute('set selected', this.getSelected(selected));
      }
    },

    getSelected: function getSelected(selected) {
      return selected;
    },

    updateProperty: function updateProperty(property) {
      return function () {
        if (property === 'selected') {
          var selected = this.get(property);
          var value = this.getSelected(selected);
          if (_ember['default'].isBlank(value)) {
            this.execute('clear');
          } else {
            this.execute('set ' + property, value);
          }
        } else {
          this.execute('set ' + property, this.get(property));
        }
      };
    },

    _onChange: function _onChange(value /*, text, $element*/) {
      this.set('selected', value);
    }
  });
});