define('semantic-ui-ember/components/ui-search', ['exports', 'ember', 'semantic-ui-ember/mixins/base'], function (exports, _ember, _semanticUiEmberMixinsBase) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend(_semanticUiEmberMixinsBase['default'], {
    module: 'search',
    classNames: ['ui', 'search'],

    /**
      Proxying all the attributes to apiSettings
    */
    apiSettings: _ember['default'].computed.alias('attrs')
  });
});