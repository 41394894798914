define('semantic-ui-ember/components/ui-rating', ['exports', 'ember', 'semantic-ui-ember/mixins/base'], function (exports, _ember, _semanticUiEmberMixinsBase) {
  'use strict';

  var Rating = _ember['default'].Component.extend(_semanticUiEmberMixinsBase['default'], {
    module: 'rating',
    classNames: ['ui', 'rating'],

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('initialRating') && this.get('rating')) {
        this.set('initialRating', this.get('rating'));
      }
    },

    didInsertElement: function didInsertElement() {
      this.set('initialized', false);
      this._super.apply(this, arguments);
      this.set('initialized', true);
    },

    _onRate: function _onRate(value) {
      // Values are set on init, and causes render errors
      if (!this.get('initialized')) {
        return;
      }
      this.set('rating', value);
    }
  });

  exports['default'] = Rating;
});